import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import loadable from '@loadable/component'

import { LanguageContextProvider } from './SignupForm/context/LanguageContext'
import SignupForm from './SignupForm/components/Form'

import './App.css'

const VendorConnect = loadable(
  () => import('./VendorConnect/components/Main'),
  {
    fallback: <div />,
  },
)

const CATSignupForm = loadable(
  () => import('./SignupForm/components/LeadForm'),
  { fallback: <div /> },
)

const TruckManagement = loadable(
  () => import('./VendorConnect/components/TruckManagement'),
  { fallback: <div /> },
)

function App() {
  return (
    <BrowserRouter>
      <LanguageContextProvider>
        <Switch>
          <Route exact path="/register" component={SignupForm} />
          <Route
            exact
            path="/:signupSource/register"
            component={CATSignupForm}
          />
          <Route
            exact
            path="/:signupSource/register/:leadId"
            component={CATSignupForm}
          />
          <Route
            exact
            path="/:vendorId/:email?/:phoneNumber?/:language?/truckManagement/:addNewTruck?"
            component={TruckManagement}
          />
          <Route
            exact
            path="/:vendorId/:email?/:phoneNumber?/:showBankScreen?/:language?/:showManageDriverScreen?"
            component={VendorConnect}
          />
          <Route exact path="*" component={() => <Redirect to="/register" />} />
        </Switch>
      </LanguageContextProvider>
    </BrowserRouter>
  )
}

export default App
