/* eslint-disable */
import locale from '#signupUtils/locale'

import {
  EMAIL_REGEX,
  CANADA_ZIP1_REGEX,
  CANADA_ZIP2_REGEX,
} from '#signupUtils/regex'

const mockContactMethodOptions = [
  { text: 'Phone', value: 'phone' },
  { text: 'Email', value: 'email' },
]

const mockRadiusOptions = [
  { text: '25', value: '25' },
  { text: '50', value: '50' },
  { text: '75', value: '75' },
  { text: '100', value: '100' },
]

const yesNoOptions = [
  { text: 'Yes', value: 'Y' },
  { text: 'No', value: 'N' },
]

const zipCodeRegex = new RegExp(
  `^\\d{5}$|${CANADA_ZIP1_REGEX}\\s${CANADA_ZIP2_REGEX}`,
)

const fields = [
  [
    {
      type: 'string',
      required: true,
      name: 'signup_info.company_name',
      label: 'vendorCompanyName',
      defaultValue: '',
      placeholder: 'vendorCompanyNamePlaceholder',
    },
    {
      type: 'string',
      required: true,
      name: 'signup_info.first_name',
      label: 'contactFirstName',
      defaultValue: '',
      placeholder: 'contactFirstNamePlaceholder',
      className: 'contactFields',
      style: { marginRight: '1%' },
    },
    {
      type: 'string',
      required: true,
      name: 'signup_info.last_name',
      label: 'contactLastName',
      defaultValue: '',
      placeholder: 'contactLastNamePlaceholder',
      className: 'contactFields',
      style: { marginLeft: '1%' },
    },
    {
      type: 'phone',
      required: true,
      name: 'signup_info.phone_number',
      label: 'contactPhoneNumber',
      defaultValue: '',
      placeholder: 'contactPhoneNumberPlaceholder',
      className: 'contactFields',
      style: { marginRight: '1%' },
      requiredIf: {
        name: 'signup_info.lead_attributes_json.preferred_contact_method',
        value: 'phone',
      },
      inputType: 'tel',
    },
    {
      type: 'string',
      name: 'signup_info.email',
      label: 'contactEmail',
      defaultValue: '',
      placeholder: 'contactEmailPlaceholder',
      className: 'contactFields',
      style: { marginLeft: '1%' },
      requiredIf: {
        name: 'signup_info.lead_attributes_json.preferred_contact_method',
        value: 'email',
      },
      validationProps: {
        pattern: EMAIL_REGEX,
      },
    },
    {
      type: 'select',
      required: true,
      name: 'signup_info.lead_attributes_json.preferred_contact_method',
      label: 'preferredContactMethod',
      defaultValue: '',
      placeholder: 'preferredContactMethodPlaceholder',
      options: mockContactMethodOptions,
      className: 'contactFields',
    },
  ],
  [
    {
      type: 'string',
      required: true,
      name: 'signup_info.service_area_code',
      label: 'serviceZip',
      defaultValue: '',
      placeholder: 'serviceZipPlaceholder',
      className: 'zipField',
      maxLength: 7,
      validationProps: {
        minLength: 5,
        pattern: zipCodeRegex,
      },
      convertToUpperCase: true,
      
    },
    {
      type: 'select',
      required: true,
      name: 'signup_info.lead_attributes_json.service_radius',
      label: 'serviceRadiusLabel',
      defaultValue: '',
      placeholder: 'serviceRadiusPlaceholder',
      options: mockRadiusOptions,
      className: 'radiusField',
    },
    {
      type: 'string',
      required: true,
      name: 'signup_info.lead_attributes_json.trucks_count',
      label: 'noOfTrucks',
      defaultValue: '',
      placeholder: 'noOfTrucksPlaceholder',
      className: 'trucksField',
      inputType: 'number',
      maxLength: 3,
      validationProps: {
        pattern: /^[0-9]{1,3}$/,
      },
    },
    {
      type: 'string',
      required: true,
      name: 'signup_info.lead_attributes_json.drivers_count',
      label: 'noOfDrivers',
      defaultValue: '',
      placeholder: 'noOfDriversPlaceholder',
      className: 'driversField',
      inputType: 'number',
      maxLength: 3,
      validationProps: {
        pattern: /^[0-9]{1,3}$/,
      },
    },
    {
      type: 'radio',
      required: true,
      name: 'signup_info.lead_attributes_json.work_during_cat',
      label: 'catLabel',
      defaultValue: null,
      options: yesNoOptions,
    },
  ],
]

export default fields
