import React from 'react'
import { Sticky } from 'semantic-ui-react'
import { Logo } from './Logo'

export default function AppBar() {
  return (
    <Sticky>
      <div className='LogoContainer'>
        <Logo height='40px' width='110px' />
      </div>
    </Sticky>
  )
}
