import en from './en'

export default {
  ...en,
  'Verify Bank Account': 'Bankkonto verifizieren',
  'E-Sign Documents': 'E-Sign-Dokumente',
  'Please click continue to verify the account.':
    'Bitte klicken Sie auf Weiter, um das Konto zu bestätigen.',
  'No documents found!': 'Keine Dokumente gefunden!',
  'Please review and electronically sign the documents below.':
    'Bitte überprüfen Sie die untenstehenden Dokumente und unterschreiben Sie sie elektronisch.',
  'Verify Account': 'Konto überprüfen',
  Continue: 'Fortsetzen',
  'E-Sign': 'E-Zeichen',
  Upload: 'Hochladen',
  'Upload Documents': 'Dokumente hochladen',
  'Please upload and submit the documents below.':
    'Bitte laden Sie die unten stehenden Dokumente hoch und senden Sie sie ab.',
  'Certificate of Insurance': 'Versicherungszertifikat',
  "Driver's License": 'Führerschein',
  Submit: 'einreichen',
  'Account Verification': 'Bestätigung des Kontos',
  'Please provide the exact penny amount deposited in your bank account.':
    'Bitte geben Sie den genauen Centbetrag an, der auf Ihrem Bankkonto hinterlegt ist.',
  'Enter the amount': 'Geben Sie den Betrag ein',
  'Amount Deposited': 'Eingezahlter Betrag',
}
