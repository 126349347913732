import React, { useState, createContext } from 'react'

export const LanguageContext = createContext()

export const LanguageContextProvider = (props) => {
  const defaultLanguage = 'en'
  const [language, setLanguage] = useState(defaultLanguage)
  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {props.children}
    </LanguageContext.Provider>
  )
}
