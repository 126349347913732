import React, { useEffect, useState } from 'react'
import renderIf from 'render-if'
import { head } from 'ramda'
import { Form, Loader } from 'semantic-ui-react'
import { Loader as MapsLoader } from '@googlemaps/js-api-loader'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

import extractAddressData from '#signupUtils/geoSuggestUtils'
import { getDecryptedKey } from '#signupUtils/signupUtils'

const styles = {
  loader: {
    position: 'absolute',
    top: '35px',
    right: '10px',
  },
  dropdownContainer: {
    fontSize: '14px',
    position: 'absolute',
    width: '100%',
    top: '100%',
    border: '1px solid #ccc',
    borderLeft: 0,
    zIndex: 10,
    boxShadow:
      'rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px',
  },
  suggestion: {
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    padding: '5px',
  },
}

const mapsLoader = new MapsLoader({
  apiKey: getDecryptedKey(process.env.API),
  version: 'weekly',
  libraries: ['places, geometry'],
})

const AutoFillAddress = (props) => {
  const {
    onChange,
    updateAddressValue,
    namePath,
    value,
    placeholder,
    completeAddressInJSON = () => {},
    displayFullAddress = false,
    ...fieldProps
  } = props
  const [currentValue, setValue] = useState(value || '')
  const [loading, setLoading] = useState(true)

  const handleSelect = (selectedAddress) => {
    const path = namePath ? `${namePath}.` : ''
    geocodeByAddress(selectedAddress)
      .then((results) => {
        const addressData = extractAddressData(head(results))
        completeAddressInJSON(addressData)
        setValue(
          displayFullAddress ? selectedAddress : addressData.address_line_1,
        )
        Object.keys(addressData).forEach((key) =>
          updateAddressValue(`${path}${key}`, addressData[key]),
        )
      })
      .catch((error) => console.error('Error', error))
  }

  const handleChange = (inputValue) => {
    setValue(inputValue)
    onChange(inputValue)
  }

  const searchOptions = {
    componentRestrictions: {
      country: ['us', 'ca'],
    },
  }

  useEffect(() => {
    mapsLoader.load().then(() => setLoading(false))
  }, [])

  return (
    <div style={{ position: 'relative', margin: '0 0 1em' }}>
      {renderIf(!loading)(
        <PlacesAutocomplete
          value={currentValue}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => {
            const { disabled, ...autoCompleteProps } = getInputProps()
            return (
              <>
                <Form.Input
                  fluid
                  placeholder={placeholder}
                  {...fieldProps}
                  {...autoCompleteProps}
                />
                {loading && (
                  <div style={styles.loader}>
                    <Loader size="small" />
                  </div>
                )}
                {suggestions.length > 0 && (
                  <div
                    className="autocomplete-dropdown-container"
                    style={styles.dropdownContainer}
                  >
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item'
                      const style = suggestion.active
                        ? { ...styles.suggestion, backgroundColor: '#fafafa' }
                        : {
                            ...styles.suggestion,
                          }
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      )
                    })}
                  </div>
                )}
              </>
            )
          }}
        </PlacesAutocomplete>,
      )}
    </div>
  )
}

export default AutoFillAddress
