import { head, isEmpty } from 'ramda'

const getAddressValue = (val) => val || ''

const getCountryValue = (val) => {
  switch (val) {
    case 'US':
      return 'USA'
    case 'CA':
      return 'CAN'
    default:
      return ''
  }
}

const extractAddressData = (gmaps) => {
  let addressLine1 = ''
  let city = ''
  let zip = ''
  let state_code = ''
  let country_code = ''

  const {
    address_components: addressComponents = [],
    formatted_address: addressDescription = ''
  } = gmaps

  addressComponents.forEach((val) => {
    const { long_name: longName, short_name: shortName, types } = val
    if (types.includes('street_number') || types.includes('route')) {
      addressLine1 = `${addressLine1} ${getAddressValue(longName)}`.trim()
    } else if (types.includes('locality')) {
      city = getAddressValue(longName)
    } else if (types.includes('administrative_area_level_1')) {
      state_code = getAddressValue(shortName)
    } else if (types.includes('postal_code')) {
      zip = getAddressValue(longName)
    } else if (types.includes('country')) {
      country_code = `${getCountryValue(shortName)}`
    }
  })
  if (isEmpty(addressLine1)) {
    const formattedAddress = addressDescription.split(',')
    addressLine1 = head(formattedAddress)
  }
  return {
    address_line_1: addressLine1,
    address_line_2: '',
    city,
    state_code,
    zip,
    country_code
  }
}

export default extractAddressData
