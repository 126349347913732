import React from 'react'
import { Icon } from 'semantic-ui-react'

import locale from '#signupUtils/locale'

const NotFoundScreen = () => (
  <>
    <div className="banner">
      <Icon name="info circle" size="huge" style={{ color: 'white' }} />
    </div>
    <div className="error-content">
      <p className="error-header">{locale('error')}</p>
      <p className="error-subtext">{locale('pageNotFound')}</p>
      <p className="error-reason">{locale('pageNotFoundReason')}</p>
    </div>
  </>
)

export default NotFoundScreen
