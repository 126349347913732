import { isEmpty, reduce, compose } from 'ramda'
import getValue from 'get-value'
import CryptoJS from 'crypto-js'

import { US_ZIP_REGEX } from '#signupUtils/regex'

export const getDefaultValues = (fieldStructure = []) => {
  if (isEmpty(fieldStructure)) return {}

  return compose(
    reduce(
      (acc, { name, defaultValue = '' }) => ({
        ...acc,
        [name]: defaultValue,
      }),
      {},
    ),
    reduce((acc, arr) => [...acc, ...arr], []),
  )(fieldStructure)
}

export const getUpdatedPayload = ({ signup_info = {} }) => {
  const { phone_number: phone, service_area_code: serviceArea } = signup_info
  const countryCode = US_ZIP_REGEX.test(serviceArea) ? 'USA' : 'CAN'
  if (!isEmpty(phone)) {
    return {
      signup_info: {
        ...signup_info,
        phone_number: phone.slice(1),
        lead_country_code: countryCode,
      },
    }
  }
  return {
    signup_info: {
      ...signup_info,
      lead_country_code: countryCode,
    },
  }
}

export const populateValues = (defaultValues = {}, formData = {}) =>
  Object.keys(defaultValues).reduce((acc, name) => {
    acc[name] = getValue(formData, name)
    return acc
  }, {})

export function getDecryptedKey(encryptedText) {
  try {
    const key = process.env.CRYPTO
    return CryptoJS.RC4.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(key), {
      keySize: 16,
      ivSize: 16,
      iv: CryptoJS.enc.Utf8.parse(key),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }).toString(CryptoJS.enc.Utf8)
  } catch (error) {
    console.error(error)
    return encryptedText
  }
}
