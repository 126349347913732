import axios from 'axios'

const post = (url = '', data = {}) =>
  axios({
    method: 'post',
    url,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })

const get = (url = '') =>
  axios({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
  })
export default { post, get }
