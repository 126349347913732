import React from 'react'
import { Icon } from 'semantic-ui-react'

import locale from '#signupUtils/locale'

const Summary = ({ leadRegistration = false }) => (
  <>
    <div className="banner" style={{ backgroundColor: 'rgb(75, 157, 79)' }}>
      <Icon name="check circle" size="huge" style={{ color: 'white' }} />
    </div>
    <div className="summary-content">
      <p className="summary-header">
        {locale(
          leadRegistration ? 'leadRegistrationComplete' : 'signUpComplete',
        )}
      </p>
      <p className="summary-subtext">{locale('thankYou')}</p>
      <p className="summary-instructions">
        {locale(leadRegistration ? 'vendorNextSteps' : 'nextSteps')}
      </p>
    </div>
  </>
)

export default Summary
