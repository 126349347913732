import { isEmpty } from 'ramda'

export const getFailedMessage = (type) =>
  ({
    SUBMISSION_FAILED: 'Unable to submit information. Please try again later.',
    SUBMISSION_SUCCESS: '',
    INTERNET_UNAVAILABLE:
      'Unable to submit information. Please check your internet connection.',
  }[type])

export const requestUnsuccessful = (type) =>
  ['SUBMISSION_FAILED', 'INTERNET_UNAVAILABLE'].includes(type)

export const getFormSource = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('source') || ''
}

export const getFailedStatus = () =>
  navigator.onLine ? 'SUBMISSION_FAILED' : 'INTERNET_UNAVAILABLE'

export const requestStatuses = [
  'SUBMISSION_FAILED',
  'SUBMISSION_SUCCESS',
  'INTERNET_UNAVAILABLE',
]

export const removeOldCache = () => {
  if (window.caches) {
    window.caches.keys().then((cacheNames) => {
      if (!isEmpty(cacheNames)) {
        Promise.all(
          cacheNames.map((cacheName) => {
            if (cacheName.startsWith('leads-signup-cache')) {
              return caches.delete(cacheName)
            }
          }),
        )
      }
    })
  }
}

export const unregisterSW = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      if (!isEmpty(registrations)) {
        registrations.forEach((reg) => reg.unregister())
      }
    })
  }
}
