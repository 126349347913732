import React from 'react'
import { Header, List } from 'semantic-ui-react'
import locale from '#signupUtils/locale'

const benefitsList = [
  {
    icon: 'line graph',
    text: 'consistentVolume',
  },
  {
    icon: 'call',
    text: 'priorDayDispatch',
    iconStyleProps: { flipped: 'horizontally' },
    iconStyle: { padding: '0 0 0 4px' },
  },
  {
    icon: 'mail',
    text: 'emailDispatch',
  },
  {
    icon: 'car',
    text: 'sellAbandonments',
  },
  {
    icon: 'money',
    text: 'weeklyPay',
    iconStyle: { padding: 0 },
  },
]

const Intro = () => (
  <>
    <Header size="tiny">{locale('interestedInWorkingText')}</Header>
    <Header size="tiny">{locale('joinCopart')}</Header>
    <Header size="tiny" style={{ fontWeight: 'normal' }}>
      {locale('copartTowingBackground')}
    </Header>
    <Header size="tiny">{locale('benefitsQuestion')}</Header>
    <List className="BenefitsList">
      {benefitsList.map(({ icon, text, iconStyle = {}, iconStyleProps }) => (
        <List.Item key={text} className="benefitsListItem">
          <List.Icon
            name={icon}
            style={{
              color: 'var(--copart-blue)',
              ...iconStyle,
            }}
            {...iconStyleProps}
          />
          <List.Content>{locale(text)}</List.Content>
        </List.Item>
      ))}
    </List>
    <Header size="tiny" style={{ fontWeight: 'normal' }}>
      {locale('areYouinterested')}
      <br />
      <br />
      {locale('clickToSignup')}
    </Header>
  </>
)

export default Intro
