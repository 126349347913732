import en from './en'

export default {
  ...en,
  'Verify Bank Account': 'Vérifier le compte bancaire',
  'E-Sign Documents': 'Documents de signature électronique',
  'Please click continue to verify the account.':
    'Veuillez cliquer sur Continuer pour vérifier le compte.',
  'No documents found!': 'Aucun document trouvé !',
  'Please review and electronically sign the documents below.':
    'Veuillez consulter et signer électroniquement les documents ci-dessous.',
  'Verify Account': 'Vérifier le compte',
  Continue: 'Continuez',
  'E-Sign': 'E-Sign',
  Upload: 'Télécharger',
  'Upload Documents': 'Télécharger des documents',
  'Please upload and submit the documents below.':
    'Veuillez télécharger et soumettre les documents ci-dessous.',
  'Certificate of Insurance': "Certificat d'assurance",
  "Driver's License": 'Le permis de conduire',
  Submit: 'Soumettre',
  'Account Verification': 'Vérification de compte',
  'Please provide the exact penny amount deposited in your bank account.':
    'Veuillez indiquer le montant exact en centimes déposé sur votre compte bancaire.',
  'Enter the amount': 'Entrez le montant',
  'Amount Deposited': 'Montant déposé',
}
