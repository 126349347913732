// Service Worker and Indexed DB features are disabled !!!

import { pathOr } from 'ramda'
import fetcher from './utils/fetcher'
import {
  getFailedMessage,
  requestUnsuccessful,
  getFormSource,
  getFailedStatus,
  requestStatuses,
  removeOldCache,
  unregisterSW as unregisterExistingSW,
} from './utils/swUtils'

const dispatchSubmitStatusEvent = (type = '') => {
  window.dispatchEvent(
    new CustomEvent('submitStatus', {
      cancelable: true,
      detail: {
        failed: requestUnsuccessful(type),
        message: getFailedMessage(type),
      },
    }),
  )
}

const sendDataWithoutSavingToIDB = (payload) =>
  sendData([
    {
      key: '',
      value: {
        ...payload,
        lead_status_code: 'OPEN',
        lead_source_code: getFormSource(),
      },
    },
  ])

const handlePostData = (registration) =>
  document.getElementById('sw').addEventListener('formSubmit', (e) => {
    const payload = pathOr({}, ['detail', 'payload', 'signup_info'], e)
    e.preventDefault()
    sendDataWithoutSavingToIDB(payload)
    // saveData(payload).then(() => {
    //   if (registration) {
    //     if (registration.sync) {
    //       registration.sync
    //         .register('signup-info-sync')
    //         .then(() => {
    //           if (!navigator.onLine) {
    //             dispatchSubmitStatusEvent('SUBMISSION_SUCCESS')
    //           }
    //         })
    //         .catch((err) => {
    //           console.log('Sync registration failed: ', err)
    //           postDataIfAvailable()
    //         })
    //     } else {
    //       console.log('Sync is not available')
    //       postDataIfAvailable()
    //     }
    //   } else {
    //     postDataIfAvailable()
    //   }
    // })
  })

export const registerServiceWorker = () => {
  const swPath = `./sw.js`
  if (false) {
    // service worker available
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(swPath)
        .then((registration) => {
          // Service worker registration successful
          registration.onupdatefound = () => {
            const newWorker = registration.installing
            newWorker.onstatechange = () => {
              if (newWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  newWorker.postMessage({ action: 'skipWaiting' })
                }
              }
            }
          }

          navigator.serviceWorker.onmessage = (event) => {
            if (event.data) {
              const { type = '' } = event.data
              if (type === 'SKIP_WAITING') {
                sessionStorage.setItem('loadNewVersion', true)
                window.location.reload()
              }
              if (requestStatuses.includes(type)) {
                dispatchSubmitStatusEvent(type)
              }
            }
          }

          handlePostData(registration)
        })
        .catch((error) => {
          console.error('ServiceWorker registration failed: ', error)
          handlePostData()
        })
    })
  } else {
    // Remove the below two function calls when enabling service worker feature.
    removeOldCache()
    unregisterExistingSW()
    console.log('service worker features have been disabled')
    // console.log('service worker is not available')
    // service worker is not available
    handlePostData()
  }
}

const fetchData = () =>
  new Promise((resolve, reject) => {
    let db = indexedDB.open('leadSignup')
    db.onsuccess = () => {
      const transaction = db.result.transaction('leadSignupStore', 'readonly')
      const objStore = transaction.objectStore('leadSignupStore')
      const cursorReq = objStore.openCursor()

      let keyValuePairs = []
      cursorReq.onsuccess = (event) => {
        let cursor = event.target.result
        if (cursor) {
          const { key, value } = cursor
          const obj = { key, value }
          keyValuePairs = [...keyValuePairs, obj]
          cursor.continue()
        } else {
          resolve(keyValuePairs)
        }
      }
    }

    db.onerror = (error) => {
      console.error('Error retrieving the data from the db: ' + error)
      reject(error)
    }
  })

const deleteRecord = (store, key) =>
  new Promise((resolve, reject) => {
    let db = indexedDB.open('leadSignup')

    db.onsuccess = () => {
      const transaction = db.result.transaction(store, 'readwrite')
      const objStore = transaction.objectStore(store)

      const deleteRequest = objStore.delete(key)

      deleteRequest.onsuccess = (event) => {}
      resolve()
    }

    db.onerror = (error) => reject(error)
  })

const sendData = (response) =>
  response.forEach(({ key, value = {} }) => {
    delete value.userOnlWhenSubmitted
    fetcher
      .post('/services/ycsleadsus/leads/create', value)
      .then(({ status }) => {
        if (status === 200) {
          dispatchSubmitStatusEvent('SUBMISSION_SUCCESS')
        } else {
          dispatchSubmitStatusEvent(getFailedStatus())
        }
        // deleteRecord('leadSignupStore', key)
      })
      .catch((error) => {
        dispatchSubmitStatusEvent(getFailedStatus())
        // deleteRecord('leadSignupStore', key)
        console.log(error)
      })
  })

const postDataIfAvailable = () =>
  fetchData().then((response) => {
    if (response[0]) {
      sendData(response)
    }
  })

const saveData = (payload) =>
  new Promise((resolve, reject) => {
    const dataObj = {
      ...payload,
      lead_status_code: 'OPEN',
      lead_source_code: getFormSource(),
      userOnlWhenSubmitted: navigator.onLine,
    }

    let db = window.indexedDB.open('leadSignup')

    db.onsuccess = () => {
      const transaction = db.result.transaction('leadSignupStore', 'readwrite')
      const objStore = transaction.objectStore('leadSignupStore')
      objStore.add(dataObj)
      resolve()
    }

    db.onerror = (error) => {
      console.error('error adding a data row in the db: ' + error)
      reject(error)
    }
  })

export const initializeDB = () => {
  let request = window.indexedDB.open('leadSignup', 1)

  request.onerror = (event) => {
    console.error('Error opening indexedDB')
  }

  request.onupgradeneeded = (event) => {
    let db = event.target.result

    switch (event.oldVersion) {
      case 1: {
        // perform actions for new db upgrade
        break
      }
      default: {
        db.createObjectStore('leadSignupStore', {
          autoIncrement: true,
        })

        db.createObjectStore('offlineFailedReqStore', {
          autoIncrement: true,
        })
      }
    }
  }
}
