import en from './en'

export default {
  ...en,
  'Verify Bank Account': 'Verificar cuenta bancaria',
  'E-Sign Documents': 'Documentos de firma electrónica',
  'Please click continue to verify the account.':
    'Haga clic en continuar para verificar la cuenta.',
  'No documents found!': '¡No se encontraron documentos!',
  'Please review and electronically sign the documents below.':
    'Revise y firme electrónicamente los siguientes documentos.',
  'Verify Account': 'Verificar Cuenta',
  Continue: 'Continuar',
  'E-Sign': 'Firma electrónica',
  Upload: 'Subir',
  'Upload Documents': 'subir documentos',
  'Please upload and submit the documents below.':
    'Cargue y envíe los documentos a continuación.',
  'Certificate of Insurance': 'Certificado de seguro',
  "Driver's License": 'Licencia de conducir',
  Submit: 'Entregar',
  'Account Verification': 'Verificación de la cuenta',
  'Please provide the exact penny amount deposited in your bank account.':
    'Proporcione el monto exacto del centavo depositado en su cuenta bancaria.',
  'Enter the amount': 'Introduce la cantidad',
  'Amount Deposited': 'Monto depositado',
}
