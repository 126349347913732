import React from 'react'
import { Message, Icon } from 'semantic-ui-react'
import renderIf from 'render-if'

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 0,
}

const MessageBar = ({ messageProps = {}, content, iconName }) => (
  <Message {...messageProps} style={containerStyle}>
    {renderIf(iconName)(
      <Icon name={iconName} style={{ marginBottom: '3px' }} />,
    )}
    <p style={{ margin: 0 }}>{content}</p>
  </Message>
)

export default MessageBar
