import React, { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import 'semantic-ui-css/semantic.min.css'
import { Form, Header, Message, Checkbox } from 'semantic-ui-react'
import renderIf from 'render-if'
import { pathOr, isEmpty, assocPath } from 'ramda'

import AppBar from '../core/AppBar'
import Footer from '../core/Footer'
import Input from '../core/Input'
import MessageBar from '../core/MessageBar'
import IntroScreen from '../IntroScreen'
import SummaryScreen from '../Summary'
import NotFoundScreen from '../NotFoundScreen'
import {
  getDefaultValues,
  getUpdatedPayload,
  populateValues,
} from '#signupUtils/signupUtils'
import { getFormSource } from '#signupUtils/swUtils'
import locale from '#signupUtils/locale'
import fieldStructure from './fieldStructure'
import {
  registerServiceWorker,
  initializeDB,
} from '../../service-worker-custom'
import '../../styles.css'

const screenTitle = (activeStep) =>
  ({
    0: locale('vendorSignup'),
    1: locale('vendorInfoText'),
  }[activeStep])

const acceptedFormSources = ['DRIVE', 'LDFRM', 'LSPTL', 'MBRPL', 'TRNAP']

const vendorAckPath = ['signup_info', 'lead_attributes_json', 'can_text_email']

const handleSWregistration = (setErrMsg, setLoading, setActiveStep) => {
  registerServiceWorker()
  // initializeDB()
  window.addEventListener('submitStatus', (e) => {
    const message = pathOr('', ['detail', 'message'], e)
    const requestFailed = pathOr(false, ['detail', 'failed'], e)
    if (requestFailed) {
      setErrMsg(message)
      setLoading(false)
    } else {
      setActiveStep(2)
      setLoading(false)
    }
  })
}

const SignupForm = (props) => {
  const [activeStep, setActiveStep] = useState(0)
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [formData, setFormData] = useState({})
  const [showNotFoundPage, setNotFoundPage] = useState(false)
  const [ackAccepted, setAckFlag] = useState('N')

  const defaultValues = getDefaultValues(fieldStructure)

  const { handleSubmit, watch, getValues, reset, ...methods } = useForm({
    defaultValues,
  })

  useEffect(() => {
    const formSource = getFormSource()
    if (acceptedFormSources.includes(formSource)) {
      handleSWregistration(setErrMsg, setLoading, setActiveStep)
    } else {
      if (!formSource) {
        props.history.push('/register?source=LDFRM')
        handleSWregistration(setErrMsg, setLoading, setActiveStep)
      } else {
        setNotFoundPage(true)
      }
    }

    const updateDimensions = () => setInnerHeight(window.innerHeight)
    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    if (errMsg) setErrMsg('')
    if (activeStep === 1) {
      if (document.querySelector('.FormContainer').scrollTop) {
        document.querySelector('.FormContainer').scrollTop = 0
      }
      if (!isEmpty(formData)) {
        reset(populateValues(defaultValues, formData))
      }
    }
    if (activeStep === 2) {
      setFormData({})
    }
    // eslint-disable-next-line
  }, [activeStep])

  const handleFormSubmit = (payload) => {
    setLoading(true)
    document.querySelector('#sw').dispatchEvent(
      new CustomEvent('formSubmit', {
        cancelable: true,
        detail: {
          payload: assocPath(
            vendorAckPath,
            ackAccepted,
            getUpdatedPayload(payload),
          ),
        },
      }),
    )
  }

  const watchContactMethod = watch(
    'signup_info.lead_attributes_json.preferred_contact_method',
  )

  const renderNotFoundPage = activeStep === 0 && showNotFoundPage
  const renderIntroPage = !showNotFoundPage && activeStep === 0
  const renderForm = activeStep === 1
  const renderSummaryPage = activeStep === 2
  const renderTitleHeader = !showNotFoundPage && [0, 1].includes(activeStep)

  const formStyle =
    (showNotFoundPage || activeStep === 2) && window.innerWidth > 1100
      ? { width: '100%', padding: 0 }
      : {}

  const towImageStyle =
    renderSummaryPage || renderNotFoundPage ? { display: 'none' } : {}

  return (
    <div style={{ height: `${innerHeight}px` }}>
      <div style={{ display: 'none' }} id="sw" />
      <AppBar />
      <div
        className="FormContainer"
        style={{ height: `${window.innerHeight - 60}px` }}
      >
        <div className="towImage" style={towImageStyle}>
          <div className="imageContainer">
            <h1 className="towImageHeader">{locale('towProviderImgHeader')}</h1>
            <h2 className="towImageSubtext">
              {locale('towProviderImgSubtext')}
            </h2>
          </div>
        </div>
        <div className="formDetails" style={formStyle}>
          {renderIf(renderNotFoundPage)(<NotFoundScreen />)}
          {renderIf(renderTitleHeader)(
            <div className="titleHeader">{screenTitle(activeStep)}</div>,
          )}
          {renderIf(renderIntroPage)(<IntroScreen />)}
          {renderIf(renderForm)(
            <>
              <FormProvider
                getValues={getValues}
                reset={reset}
                handleSubmit={handleSubmit}
                {...methods}
              >
                <Header size="tiny" style={{ fontWeight: 'normal' }}>
                  {locale('newVendorInfoSubtext')}
                </Header>
                <Form
                  id="signup-form"
                  onSubmit={handleSubmit(handleFormSubmit)}
                >
                  <div className="fieldContainer">
                    {fieldStructure.map((fieldsArr) => (
                      <div style={{ marginTop: '1em' }} key={fieldsArr[0].name}>
                        {fieldsArr.map((field) => (
                          <Input
                            {...field}
                            key={field.name}
                            watchContactMethod={watchContactMethod}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </Form>
              </FormProvider>

              {renderIf(errMsg)(
                <MessageBar
                  messageProps={{ negative: true }}
                  content={errMsg}
                  iconName="warning sign"
                />,
              )}
              <Message>
                <Message.Header style={{ marginBottom: '10px' }}>
                  Disclaimer
                </Message.Header>
                <Checkbox
                  label={locale('disclaimerText')}
                  onChange={(e, { checked }) => setAckFlag(checked ? 'Y' : 'N')}
                />
              </Message>
            </>,
          )}
          {renderIf(renderSummaryPage)(<SummaryScreen leadRegistration={false} />)}
          {renderIf(!renderNotFoundPage)(
            <Footer
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              loading={loading}
              setFormData={setFormData}
              getValues={getValues}
            />,
          )}
        </div>
      </div>
    </div>
  )
}

export default SignupForm
