import React from 'react'
import { Button } from 'semantic-ui-react'
import renderIf from 'render-if'

import locale from '#signupUtils/locale'

const styles = {
  backBtn: {
    width: '100%',
  },
  nextBtn: {
    backgroundColor: 'var(--copart-blue)',
    color: 'white',
    width: '100%',
  },
}

const nextButtonLabel = (activeStep) =>
  ({
    0: locale('signUpToday'),
    1: locale('submit'),
    2: locale('returnToHome'),
  }[activeStep])

const handleNextAction = (activeStep, setActiveStep, laadRegistration) => {
  if (activeStep === 1) {
    if (laadRegistration) {
      document.querySelector('#lead-registration-form').dispatchEvent(
        new CustomEvent('submit', {
          cancelable: true,
        }),
      )
    } else {
      document.querySelector('#signup-form').dispatchEvent(
        new CustomEvent('submit', {
          cancelable: true,
        }),
      )
    }
  } else if (activeStep === 2) {
    setActiveStep(0)
  } else {
    setActiveStep(activeStep + 1)
  }
}

const Footer = ({
  activeStep,
  setActiveStep,
  loading,
  setFormData,
  getValues,
  leadRegistration = false,
}) => {
  const nextBtnClass = activeStep === 2 ? 'returnToHomeBtn' : ''

  return (
    <div style={{ display: 'flex' }}>
      {renderIf(activeStep === 1 && !leadRegistration)(
        <Button
          style={styles.backBtn}
          onClick={() => {
            setFormData(getValues())
            setActiveStep(0)
          }}
        >
          Back
        </Button>,
      )}
      <Button
        style={styles.nextBtn}
        className={nextBtnClass}
        onClick={() =>
          handleNextAction(activeStep, setActiveStep, leadRegistration)
        }
        loading={loading}
        disabled={loading}
      >
        {nextButtonLabel(activeStep)}
      </Button>
    </div>
  )
}

export default Footer
