import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Form } from 'semantic-ui-react'
import renderIf from 'render-if'
import getValue from 'get-value'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/semantic-ui.css'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

import { isBlank } from '#signupUtils/isBlank'
import locale from '#signupUtils/locale'

import AutofillAddress from '../AutofillAddress'
import Label from './Label'
import './styles.css'

const showError = (errors, name) => !isBlank(getValue(errors, name))

const getNamePath = (name) => {
  const splitName = name.split('.')
  return splitName.slice(0, splitName.length - 1).join('.')
}

const InputHOC = (props) => {
  const { required, errors, name, label, style, className } = props
  return (
    <>
      <Form.Field
        className={className}
        style={style}
        error={showError(errors, name)}
      >
        {renderIf(label)(<Label text={locale(label)} required={required} />)}
        {props.children}
      </Form.Field>
    </>
  )
}

const getRequiredValue = (requiredIf, isRequired, watchContactMethod) => {
  if (requiredIf) {
    const { name, value } = requiredIf
    return name.endsWith('preferred_contact_method')
      ? watchContactMethod === value
      : isRequired
  }
  return isRequired
}

const getControllerPhoneProps = ({ rules = {}, ...otherProps }) => {
  const { required = false } = rules
  return {
    ...otherProps,
    rules: {
      ...rules,
      pattern: required ? /^[0-9]{10,11}$/ : /^1{1}$|^[0-9]{11}$/,
    },
  }
}

const InputField = ({
  type,
  required: isRequired = false,
  requiredIf,
  name,
  label,
  defaultValue,
  placeholder = '',
  maxLength = 255,
  options = [],
  className,
  style,
  validationProps = {},
  inputType = 'text',
  watchContactMethod,
  convertToUpperCase = false,
  disabled = false,
  value,
  format = 'MM/DD/YYYY',
  displayFullAddress = false,
  completeAddressInJSON = () => {},
  timeFormat,
  timeIntervals = 60,
  ...otherProps
}) => {
  const { control, errors, setValue, getValue, register } = useFormContext()
  const required = getRequiredValue(requiredIf, isRequired, watchContactMethod)

  const controllerProps = {
    control,
    name,
    defaultValue,
    rules: {
      required,
      ...validationProps,
    },
  }

  const fieldProps = {
    required,
    errors,
    label,
    className,
    style,
    name,
  }

  const DatepickerInput = ({ ...props }) => (
    <Form.Input type="text" icon="calendar" {...props} readOnly />
  )

  switch (type) {
    case 'string':
      return (
        <Controller
          render={({ ref, ...props }) => (
            <InputHOC {...fieldProps}>
              <Form.Input
                {...props}
                fluid
                placeholder={locale(placeholder)}
                disabled={disabled}
                maxLength={maxLength}
                onChange={(e, { value }) => {
                  const formattedVal = convertToUpperCase
                    ? value.toUpperCase()
                    : value
                  if (inputType === 'number' && maxLength) {
                    formattedVal.length <= maxLength &&
                      props.onChange(formattedVal)
                  } else {
                    props.onChange(formattedVal)
                  }
                }}
                type={inputType}
              />
            </InputHOC>
          )}
          {...controllerProps}
        />
      )
    case 'select':
      return (
        <Controller
          render={({ ref, ...props }) => (
            <InputHOC {...fieldProps}>
              <Form.Dropdown
                {...props}
                fluid
                clearable
                selection
                disabled={disabled}
                placeholder={locale(placeholder)}
                options={options}
                onChange={(e, { value }) => props.onChange(value)}
              />
            </InputHOC>
          )}
          {...controllerProps}
        />
      )
    case 'phone':
      return (
        <Controller
          render={({ ref, ...props }) => (
            <InputHOC {...fieldProps}>
              <PhoneInput
                {...props}
                country="us"
                onlyCountries={['us', 'ca']}
                countryCodeEditable={false}
                placeholder={locale(placeholder)}
                inputProps={{ required: true, type: inputType }}
                onChange={(value) => props.onChange(value)}
                containerClass="ui field input"
                inputStyle={{
                  marginLeft: '35px',
                  width: 'calc(100% - 35px)',
                  height: '38px',
                }}
                disabled={disabled}
              />
            </InputHOC>
          )}
          {...getControllerPhoneProps(controllerProps)}
        />
      )
    case 'radio':
      return (
        <Controller
          render={({ ref, ...props }) => (
            <InputHOC {...fieldProps}>
              <div style={{ display: 'flex' }}>
                {options.map(({ text, value: key }) => (
                  <Form.Radio
                    {...props}
                    key={key}
                    disabled={disabled}
                    value={key}
                    label={text}
                    checked={key === props.value}
                    onChange={(e, { value }) => props.onChange(value)}
                    style={{ marginRight: '20px' }}
                  />
                ))}
              </div>
            </InputHOC>
          )}
          {...controllerProps}
        />
      )
    case 'geo':
      return (
        <Controller
          render={({ ref, ...props }) => (
            <InputHOC {...fieldProps}>
              <AutofillAddress
                {...props}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                namePath={getNamePath(name)}
                updateAddressValue={(name, value) => setValue(name, value)}
                displayFullAddress={displayFullAddress}
                completeAddressInJSON={completeAddressInJSON}
              />
            </InputHOC>
          )}
          {...controllerProps}
        />
      )
    case 'hidden':
      return (
        <Controller
          render={(props) => {
            return (
              <input style={{ display: 'none' }} {...props} value={value} />
            )
          }}
          name={name}
        />
      )
    case 'date':
      return (
        <Controller
          render={({ ref, ...props }) => {
            return (
              <InputHOC {...fieldProps}>
                <DatePicker
                  selected={props.value}
                  {...props}
                  minDate={new Date()}
                  showTimeSelect
                  timeFormat={timeFormat}
                  timeIntervals={timeIntervals}
                  timeCaption="Time"
                  dateFormat={format}
                  customInput={<DatepickerInput />}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                  onFocus={(e) => e.preventDefault()}
                />
              </InputHOC>
            )
          }}
          control={control}
          name={name}
          defaultValue={value}
          rules={{ required: required && `${label} is required.` }}
        />
      )
    default:
      return null
  }
}

export default InputField
