export default {
  consistentVolume: 'Consistent volume',
  priorDayDispatch: 'Prior day dispatch',
  emailDispatch: 'Email dispatch',
  sellAbandonments: 'Opportunity to sell your abandonments',
  weeklyPay: 'Weekly pay',
  vendorSignup: 'Vendor Sign Up',
  vendorRegistration: 'New Vendor Registration',
  interestedInWorkingText: `Are you interested in additional income, a flexible schedule, and steady
  work with a top-notch company?`,
  joinCopart: 'Join Copart as a tow service provider!',
  copartTowingBackground: `Copart tows over 2.5 million vehicles annually in the United States and
  Canada. By joining our team, you have a partner with a nationwide presence
  and a trusted name in the auto auction industry.`,
  benefitsQuestion:
    'What are the benefits of joining Copart as a tow service provider?',
  clickToSignup: 'Click below to Sign Up!',
  areYouinterested:
    'Are you interested in joining Copart as a tow service provider?',
  vendorInfoText: 'New Vendor Information',
  towProviderImgHeader: 'Towing Service Providers',
  towProviderImgSubtext: 'Join Copart as a Tow Provider',
  newVendorInfoSubtext:
    'Please fill out the information below to the best of your ability and we will contact you with additional information.',
  newLeadInfoSubtext:
    'Please verify and fill out any missing information below',
  appVersionUpdateText: 'The app has been updated!',
  vendorCompanyName: 'Vendor Company Name',
  contactFirstName: 'Contact First Name',
  contactLastName: 'Contact Last Name',
  contactPhoneNumber: 'Contact Phone Number',
  contactEmail: 'Contact Email',
  preferredContactMethod: 'Preferred method of contact?',
  serviceZip: 'Service Zipcode',
  serviceRadiusLabel: 'Service Radius (miles)',
  noOfTrucks: 'Number of Trucks',
  noOfDrivers: 'Number of Drivers',
  catLabel: 'Are you able to work in response to Catastrophes?',
  signUpToday: 'Sign Up Today',
  submit: 'Submit',
  returnToHome: 'Return to home page',
  error: 'Error!',
  pageNotFound: 'Sorry, the page was not found.',
  pageNotFoundReason: `The page you are looking for does not exist. It may have been removed or
  is no longer available. Please check the URL and retry.`,
  signUpComplete: 'Sign Up Complete',
  thankYou: 'Thanks for submitting your information!',
  nextSteps: `Copart will review your details and contact you about the next steps in
  the process.`,
  vendorCompanyNamePlaceholder: 'Enter vendor company name',
  contactFirstNamePlaceholder: 'Enter contact first name',
  contactLastNamePlaceholder: 'Enter contact last name',
  contactPhoneNumberPlaceholder: 'Enter contact phone number',
  contactEmailPlaceholder: 'Enter contact email address',
  preferredContactMethodPlaceholder: 'Select preferred contact method',
  serviceZipPlaceholder: 'ex 12345 / A1A 1A1',
  serviceRadiusPlaceholder: 'Select radius',
  noOfTrucksPlaceholder: 'Enter number of trucks',
  noOfDriversPlaceholder: 'Enter number of drivers',
  disclaimerText:
    'By checking here, you give Copart permission to send you text messages and emails.',
  completedNoteSubtext:
    'You have successfully completed all of you required documents.',
  completedNoteText: `You're all set!`,
  leadRegistrationComplete: 'Registration Complete',
  vendorNextSteps:
    'You will soon receive a link through text/email for e-signing and uploading necessary documents.',
  CATHeader: 'Hurricane Ida Response',
  catInfoSubtext: `We're glad you're interested in becoming a subhauler for Copart. We have an immediate need to help pick up cars impacted by Hurricane Ida in New York, New Jersery and Pennsylvania`,
  w9Esigninfo: `In order to avoid delays in your application, please make sure this document is filled out in its entirety - including City, State and Zip.`,
}
